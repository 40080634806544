<template>
  <div class="content">
    <h1 class="page-title">
      {{ title }}<small class="text-muted">{{ titleEng }}</small>
    </h1>
    <input-basic
      v-model="forms.name"
      :v="v$.forms.name"
      label="姓名"
      col="row mb-3 mt-3"
      labelcol="col-form-label col-sm-2 text-end"
      inputcol="col-sm-10"
      :maxlength="v$.forms.name.maxLength.$params.max"
    />
    <input-basic
      v-model="forms.engName"
      :v="v$.forms.engName"
      label="英文名"
      col="row mb-3 mt-3"
      labelcol="col-form-label col-sm-2 text-end"
      inputcol="col-sm-10"
      :maxlength="v$.forms.engName.maxLength.$params.max"
    />
    <radio-basic
      v-model="forms.gender"
      :v="v$.forms.gender"
      input-name="gender"
      :is-inline="true"
      :items="options.gender"
      label="性別"
    />
    <input-basic
      v-model="forms.passport"
      :v="v$.forms.passport"
      label="帳號(Email)"
      col="row mb-3 mt-3"
      labelcol="col-form-label col-sm-2 text-end"
      inputcol="col-sm-10"
      :maxlength="v$.forms.passport.maxLength.$params.max"
    />
    <input-basic
      v-model="forms.password"
      :v="v$.forms.password"
      label="密碼"
      col="row mb-3 mt-3"
      labelcol="col-form-label col-sm-2 text-end"
      inputcol="col-sm-10"
      type="password"
      :maxlength="v$.forms.password.maxLength.$params.max"
    />
    <input-basic
      v-model="forms.mechanism"
      :v="v$.forms.mechanism"
      label="服務單位"
      col="row mb-3 mt-3"
      labelcol="col-form-label col-sm-2 text-end"
      inputcol="col-sm-10"
      :maxlength="v$.forms.mechanism.maxLength.$params.max"
    />
    <input-basic
      v-model="forms.title"
      :v="v$.forms.title"
      label="職稱"
      col="row mb-3 mt-3"
      labelcol="col-form-label col-sm-2 text-end"
      inputcol="col-sm-10"
      :maxlength="v$.forms.title.maxLength.$params.max"
    />
    <input-basic
      v-model="forms.telephone"
      :v="v$.forms.telephone"
      label="電話"
      col="row mb-3 mt-3"
      labelcol="col-form-label col-sm-2 text-end"
      inputcol="col-sm-10"
      :maxlength="v$.forms.telephone.maxLength.$params.max"
    />
    <input-basic
      v-model="forms.mobile"
      :v="v$.forms.mobile"
      label="手機"
      col="row mb-3 mt-3"
      labelcol="col-form-label col-sm-2 text-end"
      inputcol="col-sm-10"
      :maxlength="v$.forms.mobile.maxLength.$params.max"
    />
    <input-basic
      v-model="forms.address"
      :v="v$.forms.address"
      label="地址"
      col="row mb-3 mt-3"
      labelcol="col-form-label col-sm-2 text-end"
      inputcol="col-sm-10"
      :maxlength="v$.forms.address.maxLength.$params.max"
    />
    <p class="text-center mt-3">
      <button
        type="button"
        class="btn btn-primary"
        :disabled="submitStatus === 'PENDING'"
        @click="clickSubmit"
      >
        <font-awesome-icon icon="check" /> 註冊
      </button>
    </p>
  </div>
</template>

<script>
import { PublicAPI } from '@/api/index'
import AxiosErrMsg from '@/helper/AxiosErr.js'
import isValidScroll from '@/helper/useVuelidateScroll.js'

import InputBasic from '@/components/form/input.vue'
import TextBasic from '@/components/form/textarea.vue'
import RadioBasic from '@/components/form/radio.vue'

import useVuelidate from '@vuelidate/core'
import { required, email, minLength, maxLength, numeric } from '@vuelidate/validators'

export default {
  components: { InputBasic, RadioBasic },
  setup() {
    return {
      v$: useVuelidate()
    }
  },
  data() {
    return {
      submitStatus: null,
      title: this.$route.meta.nav,
      titleEng: this.$route.meta.navEng,
      send: {},
      forms: {
        name: '',
        engName: '',
        gender: '',
        passport: '',
        password: '',
        mechanism: '',
        title: '',
        telephone: '',
        mobile: '',
        address: ''
      },
      options: {
        gender: [
          { id: '1', name: '男' },
          { id: '0', name: '女' }
        ]
      }
    }
  },
  methods: {
    async contentPost(formData) {
      let loader = this.$loading.show()
      await PublicAPI.Register(this.send)
        .then(response => {
          this.$swal({
            title: '註冊成功',
            icon: 'success'
          })
          this.$router.push({ name: 'login' })
        })
        .catch(err => {
          AxiosErrMsg(err, this.$swal)
        })
      loader.hide()
      this.submitStatus = null
    },
    clickSubmit() {
      this.v$.$touch()
      if (this.v$.$error) {
        isValidScroll(this)
        return
      }
      this.submitStatus = 'PENDING'
      this.send = JSON.parse(JSON.stringify(this.forms))
      this.contentPost()
    }
  },
  validations() {
    return {
      forms: {
        name: { required, maxLength: maxLength(30) },
        engName: { required, maxLength: maxLength(50) },
        gender: { required, maxLength: maxLength(1) },
        passport: { required, email, maxLength: maxLength(50) },
        password: { required, minLength: minLength(8), maxLength: maxLength(24) },
        mechanism: { required, maxLength: maxLength(100) },
        title: { required, maxLength: maxLength(50) },
        telephone: { required, maxLength: maxLength(30) },
        mobile: { required, maxLength: maxLength(30) },
        address: { required, maxLength: maxLength(70) }
      }
    }
  }
}
</script>

<style></style>
